import { motion, useMotionValue } from 'framer-motion'
import { easingTransitions } from '@lib/utils/animate'

const Anker = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 73 17" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.0008 0.175781H68.5152L65.125 4.96575L66.8694 7.434L72.0008 0.175781Z"
        fill="#00A9E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.4304 8.80489L64.355 5.87099L60.3345 0.185547H56.8457L62.6774 8.42716L56.8457 16.672H60.3345L64.4218 10.8922L68.5155 16.6785H72.0011L66.4304 8.80489Z"
        fill="#00A9E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.4394 0.175781H36.498V15.1904C36.498 15.998 37.1379 16.6558 37.9369 16.6558H48.015V13.7381H39.4362V0.175781H39.4394Z"
        fill="#00A9E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.959 16.6558H53.9003V13.7414V0.175781H50.959V16.6558Z"
        fill="#00A9E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3282 0C24.7775 0 24.2396 0.0553566 23.7207 0.159557L24.4624 3.08043C24.7425 3.03159 25.0322 3.00879 25.3282 3.00879C25.6879 3.00879 26.0381 3.04461 26.3787 3.11625L25.8758 0.0195376C25.6943 0.00651254 25.5129 0 25.3282 0Z"
        fill="#00CE7C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5072 2.8623L29.4062 4.97888C29.8105 5.4836 30.1288 6.06322 30.3358 6.69493L32.7073 4.7021C32.3858 4.03456 31.9815 3.41913 31.5072 2.86556V2.8623Z"
        fill="#00CE7C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3943 6.77002L30.5389 7.52873C30.5867 7.81528 30.6089 8.1116 30.6089 8.41443C30.6089 8.78239 30.5739 9.14058 30.5039 9.489L33.5312 8.97451C33.5439 8.78891 33.5503 8.6033 33.5503 8.41443C33.5503 7.8511 33.4961 7.30079 33.3943 6.77002Z"
        fill="#00CE7C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9679 0.442871L27.1816 3.35398C27.7896 3.58843 28.3435 3.93359 28.8242 4.36667L29.9033 1.42626C29.308 1.01923 28.6587 0.687091 27.9679 0.446127V0.442871Z"
        fill="#00CE7C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.285 11.993C29.215 12.0777 29.1386 12.1559 29.0622 12.234C28.1041 13.2109 26.7862 13.8166 25.3251 13.8166C22.4061 13.8166 20.0409 11.3971 20.0409 8.41115C20.0409 6.91978 20.633 5.56843 21.588 4.58829C21.7089 4.46455 21.8363 4.34733 21.9668 4.23661C22.4602 3.82307 23.0268 3.49744 23.6443 3.28578L21.6962 0.859863C21.0436 1.18875 20.442 1.60229 19.9009 2.08748C19.7672 2.20796 19.6367 2.3317 19.5093 2.46195C18.0196 3.98262 17.0996 6.08617 17.0996 8.41115C17.0996 13.0578 20.7826 16.8253 25.3251 16.8253C27.5979 16.8253 29.6511 15.8843 31.1409 14.3604C31.5197 13.9729 31.8603 13.5495 32.1595 13.0937C32.5606 12.4847 32.8821 11.8205 33.1177 11.1139L30.275 10.3096C30.0458 10.9315 29.7084 11.5013 29.285 11.9898V11.993Z"
        fill="#00A9E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75831 6.96819H5.47049C4.14626 6.96494 3.27724 6.22251 3.27724 4.96233C3.27724 4.27526 3.79929 3.09975 5.47049 3.09975L11.8147 3.13556L14.0079 0.182129H5.45139C2.88252 0.182129 0.628783 2.09356 0.437788 4.94605C0.339108 6.39183 0.762481 7.59991 1.74292 8.72332C2.39548 9.46901 3.33772 9.88581 4.31497 9.88581H9.73921C11.4136 9.88581 11.9325 11.0581 11.9325 11.7451C11.9325 13.0021 11.0603 13.7477 9.73921 13.7477H2.60876L0.425053 16.6719H10.8915C11.8688 16.6719 12.811 16.2551 13.4636 15.5094C14.444 14.386 14.8674 13.1779 14.7687 11.7321C14.5777 8.87636 12.324 6.96819 9.75512 6.96819H9.75831Z"
        fill="#00A9E0"
      />
    </svg>
  )
}

export default Anker
